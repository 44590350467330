<template>
  <div class="table home row_">
    <div></div>
    <Presentation :ready=ready class="c1 flyer"></Presentation>
    <div></div>
  </div>
</template>

<script>
import Presentation from "./Presentation";
export default {
  name: 'Home',
  components: {Presentation},
  props: {
    msg: String,
    ready: Boolean,
  }
}
</script>

<style lang="scss">
  @import '../assets/css/base';
  .home {
    width: 100%;
    height: 100%;
  }
</style>
