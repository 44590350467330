<template>
  <div class="blueimp-thumbnails">
    <transition-group v-if="!carousel" name="list" tag="div" class="tgGroup" ref="tbAnchors">
      <a v-for="(item, i) in items" :href="itemUrl(item)" :key="i" class="tbItem"
         :type="item.youtubeId ? 'text/html': ''"
         :data-youtube="item.youtubeId ? item.youtubeId : ''" data-galery @click="focusItem">
        <img :src="item.thumbnailUrl" />
      </a>
    </transition-group>
    <div class="observer" v-observe-visibility="visibilityChanged" />
    <div class="observer bottom" v-observe-visibility="visibilityChanged" />

    <div
      :id="refId"
      class="blueimp-gallery blueimp-gallery-controls"
      :class="carousel? 'blueimp-gallery-carousel': ''"
      :aria-label="label"
      aria-modal="true"
      role="dialog"
      @click="onclick"
    >
      <div class="slides" aria-live="polite" :ref="slidesRef"></div>
      <h3 class="title"></h3>
      <a
        class="prev"
        :aria-controls="refId"
        aria-label="previous slide"
        :aria-keyshortcuts="carousel? '': 'ArrowLeft'"
      ></a>
      <a
        class="next"
        :aria-controls="refId"
        aria-label="next slide"
        :aria-keyshortcuts="carousel? '': 'ArrowRight'"
      ></a>
      <a
        v-if="!carousel"
        class="close"
        :aria-controls="refId"
        aria-label="close"
        aria-keyshortcuts="Escape"
      ></a>
      <a
        class="play-pause"
        :aria-controls="refId"
        aria-label="play slideshow"
        aria-keyshortcuts="Space"
        :aria-pressed="carousel"
        role="button"
      ></a>
      <ol v-if="blueimpOptions.thumbnailIndicators || carousel" class="indicator" :class="carousel? 'carousel': ''"></ol>
    </div>
    <Youtube v-if=showVideo @close=toggleVideo(false)
             v-bind:id=videoId
             v-bind:title=videoTitle
             v-bind:close-button=true
    />
  </div>
</template>

<script>
  import {_fetch} from "../helpers/base"
  import Gallery from 'blueimp-gallery/js/blueimp-gallery.min'
  import Youtube from "./Youtube";

  export default {
    name: 'Blueimp',
    components: {Youtube},
    props: {
      title: String,
      itemId: {
        default: "",
        type: String,
      },
      galeryPrefixUrl: {
        // default: "http://localhost:5000",
        default: "https://api.unautrepais.com/galery",
        type: String,
      },
      autoFetch: {
        default: true,
        type: Boolean,
      },
      extraItems: {
        default: null,
        type: Array,
      },
      maxItems: {
        default: -1,
        type: Number,
      },
      pageSize: {
        default: 5,
        type: Number,
      },
      preloadCount: {
        default: -1, // if negative, preload until done
        type: Number,
      },
      withThumbnails: {
        default: true,
        type: Boolean,
      },
      blueimpOptions: {
        default: () => {
          return {
            slideshowInterval: 3500,
            slideshowTransitionDuration: 1,
            transitionDuration: 1,
            preloadRange: 4,
            continuous: false,
            carousel: false,
            thumbnailIndicators: false,
            thumbnailProperty: 'thumbnailUrl',
            toggleControlsOnSlideClick: false,
          }
        },
        type: Object,
      },
      blueimpOverloads: {
        default: () => {},
        type: Object,
      },
      defaultCarouselOpts: {
        default: () => {
          return {
            slideshowInterval: 10000,
            slideshowTransitionDuration: 700,
            transitionDuration: 700,
            startSlideshow: true,
            continuous: true,
            unloadElements: false,
          }
        }
      }
    },
    computed: {
      refId() {
        return 'blueimp-' + this.id
      },
      slidesRef() {
        return 'slides-' + this.id
      },
      carousel() {
        return this.options.carousel
      },
      label() {
        return this.carousel? 'image carousel': 'image gallery'
      }
    },
    created() {
      if (this.carousel) {
        this.options = Object.assign(this.options, this.defaultCarouselOpts)
      }
      this.options = Object.assign(this.options, this.blueimpOverloads)
      if (this.extraItems) {
        this.items = this.extraItems
      }
    },
    mounted() {
      if (this.autoFetch) this.fetchNext()
      this.$el.$vue = this
    },
    data() {
      return {
        current: null,
        idx: 0,
        galery: null,
        items: [],
        pageToken: null,
        loading: false,
        done: false,
        calls: 0,
        callsBuffer: this.preloadCount,
        observerVisible: false,
        options: Object.assign(this.blueimpOptions, this.blueimpOverloads),
        id: Math.ceil(Math.random() * 1000000),
        showVideo: false,
        videoId: '',
        videoTitle: '',
        reloaded: false, // true when reload() called at least once (hack from Presentation's resizeVP)
      }
    },
    watch: {
      items: function(v) {
        if (!this.carousel || this.galery) return
        if (v.length > 0) {
          this.openGalery(0)
        }
      }
    },
    methods: {
      onslide(idx) {
        this.idx = idx
        const item = this.items[idx]
        this.current = item

        if (!this.carousel) {return}
        if (!this.$refs[this.slidesRef]) return

        // get current item, assign container element in slides
        item.$el = this.$refs[this.slidesRef].children[idx]

        // no caption available
        if (!this.current.caption) return

        // if needed, do the DOM insertion
        if (!this.current.$caption) this.insertCaption(item)

        this.resizeCaption(item)
      },
      // a contains b ?
      contains(a, b, yMargin=40) {
        if (!a || !b) return
        if (!a.x || !b.x) {
          if (!a.getBoundingClientRect || !b.getBoundingClientRect) return
          a = a.getBoundingClientRect()
          b = b.getBoundingClientRect()
        }
        return (
          b.x >= a.x &&
          b.y - yMargin >= a.y &&
          (b.x + b.width) <= (a.x + a.width) &&
          (b.y + b.height) + yMargin <= (a.y + a.height)
        )
      },
      resetCaptionsSizes() {
        this.items.forEach((e) => {
          e.resized = false
        })
      },
      async resizeCaption(item) {
        if (!item || !item.$caption || item.resized || !this.reloaded) return

        const rCont = item.$caption
        const r = item.$caption.children[0]
        // first clear custom props, let cssheets do the job auto
        r.style['line-height'] = null
        r.style['font-size'] = null
        r.style['margin-top'] = null
        const delay = (v) => {
          return new Promise(resolve => {
            setTimeout(() => {resolve('')}, v)
          })
        }
        await delay(50)

        const szStep = 0.04;
        const lhStep = 0.04;
        const parseUnit = function(s) {
          const match = s.match(/(\d+|\d+\.\d+)(px|pt|em|%)/)
          if (!match) return null
          return [Number(match[1]), match[2]]
        }
        const resizeHandler = function(delay) {
          return new Promise(resolve => {
            r.style['margin-top'] = '0px'
            const cputed = window.getComputedStyle(r)
            const lh = parseUnit(cputed.getPropertyValue("line-height"))
            if (lh) {
              r.style['line-height'] = ((1 - lhStep) * lh[0]) + lh[1]
            }
            const sz = parseUnit(cputed.getPropertyValue("font-size"))
            if (sz) {
              r.style['font-size'] = ((1 - szStep) * sz[0]) + sz[1]
            }
            setTimeout(() => {resolve('')}, delay);
          })
        }

        // loop while r bigger than rCont
        while (!this.contains(rCont, r)) {
          // get computed style as ref
          await resizeHandler(50)
        }
        item.resized = true
      },
      insertCaption(item) {
        // add video class for play thingy
        if (this.current.caption.video) {
          item.$el.className += " video"
          if (this.current.caption.position)
            item.$el.className += " " + this.current.caption.position
        }

        // create $caption element
        const $caption = document.createElement('div')
        this.current.$caption = $caption
        $caption.className = "caption "
        if (this.current.caption.position)
          $caption.className += this.current.caption.position

        // create $content element
        const $content = document.createElement('div')

        // insert <p> text
        const $p = document.createElement('p')
        $p.innerHTML = this.current.caption.text
        $content.appendChild($p)

        // insert <a> link
        if (this.current.caption.link) {
          const linkItems = this.current.caption.link.replace("<", "").replace(">", "").split("|")
          if (linkItems.length === 2) {
            const $a = document.createElement('a')
            $a.textContent = linkItems[1]
            if (!linkItems[0].startsWith("/") && !linkItems[0].startsWith("http://")) {
              linkItems[0] = "/" + linkItems[0]
            }
            $a.href = linkItems[0]
            $a.target = "_blank"
            $content.appendChild($a)
          }
        }

        // insert in container
        $caption.appendChild($content)
        this.current.$el.appendChild($caption)
      },
      onclose() {
        this.galery = null
      },
      onclick(ev) {
        // disable slideshow on any click interaction
        if (ev.target.className !== "play-pause") {
          this.galery.pause();
        }

        // check that current item has video meta
        const video = this.current.caption ? this.current.caption.video: null
        // trick to ensure user clicked on the image or play button only
        const targetAccepted = ev.target.classList.contains("video") ||
          ev.target.tagName.toLowerCase() === 'img'

        // load video meta and toggle video
        if (video && video.id && targetAccepted) {
          this.videoId = video.id
          this.videoTitle = video.title
          this.toggleVideo(true)
        }
      },
      toggleVideo(on) {
        this.showVideo = on
        on ? this.galery.pause(): this.galery.play()
      },
      visibilityChanged(visible) {
        this.observerVisible = visible
        // console.debug("visibilityChanged, reset callsBuffer to", this.preloadCount)
        this.callsBuffer = this.preloadCount
        if (this.loading || this.done) return
        return this.fetchNext()
      },
      addItems(p) {
        const that = this;
        return p.then(response => {
          return response.json().then(json => {
            this.calls++
            for (let i = 0; i < json.files.length; i++) {
              json.files[i]['href'] = that.itemUrl(json.files[i])
              json.files[i]['thumbnailUrl'] = that.thumbnailUrl(json.files[i])
              const desc = json.files[i]['description']
              if (desc) {
                try {
                  json.files[i]['caption'] = JSON.parse(desc)
                } catch {
                  json.files[i]['caption'] = {"text": desc}
                }
              }
            }
            // console.debug("loaded", json.files.length, " items, calls:", this.calls, "total:", this.items.length)
            this.items = this.items.concat(json.files)
            if (this.galery) {
              this.galery.add(json.files)
            }
            if (this.maxItems > 0 && this.items.length >= this.maxItems) {
              this.items = this.items.slice(0, this.maxItems)
              this.done = true
              // console.debug("max items quota reached", this.maxItems, this.items.length)
              return
            }
            this.pageToken = json.pageToken
            if (!this.pageToken) {
              this.done = true
              return
            }
            this.callsBuffer--
            if (this.callsBuffer === 0 && this.observerVisible) {
              this.callsBuffer = this.preloadCount
            }
            // works for positive & negative values..
            if (this.callsBuffer !== 0) {
              // auto-populate in background
              // console.debug("auto fetchNext:", this.callsBuffer)
              return this.fetchNext()
            }
            return this.items
          })
        }).finally(() => this.loading = false)
      },
      fetchNext(pageSize=this.pageSize, withThumbnail=this.withThumbnails) {
        this.loading = true
        const params = {
          paginated: this.pageSize > 0,
          withThumbnail, pageSize,
        }
        if (this.pageToken) params['pageToken'] = this.pageToken
        // console.debug("fetchNext", params)
        const p = _fetch(this.galeryPrefixUrl + "/list/" + this.itemId, null, params)
        return this.addItems(p)
      },
      itemUrl(item) {
        if (item && item.fetchUrl) return item.fetchUrl
        if (!item || !item.fetchEndpoint) return "";
        return this.galeryPrefixUrl + item.fetchEndpoint;
      },
      thumbnailUrl(item) {
        if (item && item.thumbnail) {
          return "data:" + item.thumbnailMimeType + ";base64," + item.thumbnail
        }
        if (item && item.thumbnailUrl) return item.thumbnailUrl
        if (!item || !item.thumbnailEndpoint) return "";
        return this.galeryPrefixUrl + item.thumbnailEndpoint;
      },
      focusItem(event) {
        event = event || window.event
        // event.preventDefault()
        const target = event.target || event.srcElement
        const link = target.src ? target.parentNode : target
        this.openGalery(link, event)
      },
      openGalery(idx, event) {
        const options = Object.assign(this.options,
          {
            index: idx,
            event: event,
            container: '#' + this.refId,
            onslide: this.onslide,
            onclose: this.onclose,
          }
        )
        let links = this.items
        this.galery = Gallery(links, options)
      },
      reload() {
        this.reloaded = true
        if (this.galery) {
          this.galery.initSlides(true)
          this.resetCaptionsSizes()
          this.resizeCaption(this.current)
        }
      }
    },
  };
</script>

<style lang="scss">
  @import '../assets/css/positions';
  @import '../assets/css/shapes';
  @import '../assets/css/mixins';
  @import '../assets/css/blueimp/blueimp';
  @import '../assets/css/blueimp/blueimp-gallery-indicator';

  .blueimp-thumbnails > div.tgGroup {
    position: relative;

    display: -webkit-flex;
    display: flex;

    -webkit-flex: 0 1 auto;
    -webkit-flex-flow: row wrap;
    -webkit-align-items: center;
    -webkit-justify-content: center;

    flex: 0 1 auto;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    text-align: center;
    overflow: hidden;
    a {
      $h: 165px;
      $h2: 125px;
      $h3: 80px;
      $ratio: 0.75;
      height: $h;
      width: $h/$ratio;
      @include respond-below(l) {
        &, img {
          height: $h2;
          width: $h2/$ratio;
        }
      }
      @include respond-below(s) {
        &, img {
          height: $h3;
          width: $h3/$ratio;
        }
      }
      margin: .2em;
      img {
        flex: 1;
        object-fit: cover;
        object-position: center;
        margin: .15em;
      }
    }
    .observer {
      position: absolute;
      display: block;
      width: 100%;
      bottom: 200px;
      padding: 20px;
      &.bottom {
        bottom: 0;
      }
    }
    .bspinner {
      margin: calc(50% - 50px) auto auto auto;
    }
  }

  .blueimp-gallery {
    &.blueimp-gallery-carousel {
      .slides {
        .slide {
          img.slide-content {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          &.video {
            @include videoPlay();
            &.right {
              @include videoPlay(-16%);
            }
            &.left {
              @include videoPlay(16%);
            }
          }

          // remove padding if carousel is not continuous (no arrow)
          /* ---------------
          &:first-of-type {
            div.caption.left {
              padding-left: 10px;
              width: calc(22% + 66px);
            }
          }
          &:last-of-type {
            div.caption.right {
              padding-right: 10px;
              width: calc(22% + 66px);
            }
          }
          ------------------ */

          div.caption {
            cursor: default;
            position: absolute;
            display: flex;
            align-items: center;
            flex: 1;
            height: 100%;
            top: 0;
            bottom: 0;
            box-sizing: border-box;
            $capWidth: 25%;
            $capPad: 70px;

            width: $capWidth;
            @include respond-below(xl) {
              width: 33%;
            }
            background: rgba(255, 255, 255, .9);
            color: black;

            div {
              margin-top: 60px;
              @include respond-below(s) {
                margin-top: 0;
              }
            }

            // left/right positionning
            left: 0; right: auto;
            padding: 0 $capPad 0 $capPad+20;
            @include respond-below(xl) {
              padding: 0 $capPad/1.7 0 ($capPad+20)/1.7;
            }
            @include respond-below(l) {
              padding: 0 $capPad/3 0 ($capPad+20)/3;
            }
            text-align: center;
            &.right {
              right: 0;
              left: auto;
              padding: 0 $capPad+20 0 $capPad;
              @include respond-below(xl) {
                padding: 0 ($capPad+20)/1.7 0 $capPad/1.7;
              }
              @include respond-below(s) {
                padding: 0 ($capPad+20)/3 0 $capPad/3;
              }
            }

            font-size: 1em;
            @include respond-below(ll) {
              font-size: .9em;
            }
            line-height: 1.35em;

            // content
            &.right {
              p {
                text-align: left;
              }
            }
            p {
              line-height: inherit;
              font-size: inherit;
              text-align: right;
            }
            a {
              display: inline-block;
              text-align: center;
              margin-top: 2em;
              @include button();
              @include respond-below(s) {
                @include button($small: true);
              }
            }
          }
        }
      }
    }
  }
</style>
