export const getParenter = {
  methods: {
    getParent(name) {
      let p = this.$parent
      while (typeof p !== 'undefined') {
        if (p.$options && p.$options.name === name) {
          return p
        } else {
          p = p.$parent
        }
      }
      return false
    }
  }
}
