<template>
  <div>
    <section class="hameau" ref="hameau">
      <h1>Un projet en construction...</h1>
      <Galery class="thumbnails tbCarousel" :itemId="'1Tg5HWcNwmU-VHaD3mNmA0Us08g6D0ACu'" :blueimp-overloads="{'carousel': true}"></Galery>
    </section>
    <section class="team" ref="equipe">
      <h1>... soutenu par ses habitants :)</h1>
      <Galery class="thumbnails tbCarousel" :itemId="'118g7Ok6InQoJogogr-VhEcKP1l99Ob21'" :blueimp-overloads="{'carousel': true}"></Galery>
    </section>
    <section class="goals" ref="valeurs">
      <h1>Un lieu qui a pour vocation de...</h1>
      <div class="pullright">
        <table>
          <tr class="tb1">
            <td>
              <img src="../assets/img/bullet-1.png" />
            </td>
            <td>
              <h4>
                Soutenir la vie dans son ensemble
              </h4>
              <p>
                Création d'un jardin-forêt et d'un pépinière, de systèmes pour augmenter l'autonomie
              </p>
            </td>
          </tr>
          <tr class="tb2">
            <td>
              <img src="../assets/img/bullet-2.png"/>
            </td>
            <td>
              <h4>
                Créer et préserver l'harmonie
              </h4>
              <p>
                Mise en place d'une communication saine et bienveillante sur le lieu, d'un accueil de qualité, d'ateliers
                pour contribuer au bien-être...
              </p>
            </td>
          </tr>
          <tr class="tb3">
            <td>
              <img src="../assets/img/bullet-3.png"/>
            </td>
            <td>
              <h4>
                Impulser des dynamiques locales et vivantes
              </h4>
              <p>
                Création d'un fournil et d'un petit café citoyen, d'activités locales régulières, conférences, débats,
                accueil d'artisans et de professions variées...
              </p>
            </td>
          </tr>
          <tr class="tb4">
            <td>
              <img src="../assets/img/bullet-4.png"/>
            </td>
            <td>
              <h4>
                Maintenir l'équilibre décisionnel
              </h4>
              <p>
                Gouvernance horizontale, prise de décision base de consentement individuel et conscient, création de
                groupes de travail...
              </p>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section class="financement" ref="financement">
      <h1>Campagne de financement</h1>
      <p>
        Nous avons lancé cette année une campagne de financement sous forme de prêts personnels et de dons,
        vous pouvez consulter notre appel à l'adresse <a href="/email">unautrepais.com/email</a>
      </p>
      <p style="margin-top: 1em;">
        Formulaire de participation <a href="/form">unautrepais.com/form</a>
      </p>
      <p style="margin-top: 1em;">
        Merci infiniment pour votre soutien 🥰
      </p>
    </section>
    <section class="contact" ref="contact">
      <h1>Suivez-nous</h1>
      <div>
        <div class="social">
          <div class="uap">
            <figure>
              <img src="../assets/img/logos/logo_big.png"/>
              <p>
                <a href="mailto:contact@unautrepais.com">contact@unautrepais.com</a>
              </p>
            </figure>
          </div>
          <div class="logos">
            <p>Sur les réseaux :</p>
            <div class="logos1">
              <a href="https://www.youtube.com/channel/UCYtQeFSsa4HX1XcrYW9uSzQ">
                <img src="../assets/img/logos/yt.svg"/>
              </a>
              <a href="https://www.instagram.com/unautrepais/">
                <img src="../assets/img/logos/insta.png"/>
              </a>
              <a href="https://www.facebook.com/UnAutrePais">
                <img src="../assets/img/logos/fb.png"/>
              </a>
            </div>
            <div class="logos2">
              <a href="https://www.paypal.com/donate/?hosted_button_id=CLRBPLXB7TUXA" target="_blank">
                <img class="ppimg" alt="Don Paypal" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donate_LG.gif" />
              </a>
            </div>
          </div>
        </div>
        <div class="maps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2914.308499526535!2d1.9508014610480282!3d43.07700916379187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12afaffd8bc902a9%3A0xd9b5229cca55173b!2sUn%20Autre%20Pa%C3%AFs!5e0!3m2!1sen!2sfr!4v1648728028404!5m2!1sen!2sfr"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </section>
    <section class="galery" ref="galerie">
      <h1>Galerie photo</h1>
      <Galery class="thumbnails" v-bind:itemId="'1X8bQP5wi9Hi1y5r0ecWoQggdmaJq4_oo'"></Galery>
    </section>
  </div>
</template>

<script>
  import Blueimp from "./Blueimp";
  import {getParenter} from "../helpers/mixins"

  export default {
    name: 'Presentation',
    mixins: [getParenter],
    props: {
      ready: Boolean,
    },
    components: {Galery: Blueimp},
    created() {
      window.addEventListener("resize", this.resizeViewport)
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeViewport)
    },
    methods: {
      roadmapImageSrc(item) {
        return require(`../assets/img/roadmap-${item.id}.jpg`)
      },
      scrollTo(ref) {
        this.getParent('App').hideMenu()
        if (ref in this.$refs) {
          this.$scrollTo(this.$refs[ref], {offset: -this.scrollOffset()})
        }
      },
      getVP() {
        const w = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const h = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        this.ratio = h / w
        return {w, h}
      },
      scrollOffset() {
        return this.getParent('App').scrollOffset()
      },
      resizeViewport() {
        const vp = this.getVP()
        const sectionWidth = this.$el.clientWidth;

        [this.$refs.hameau, this.$refs.equipe].forEach((e) => {
          const h1 = e.querySelector('h1')
          const gal = e.querySelector('.tbCarousel')
          if (!h1 || !gal) return

          // reset style for calcs
          gal.style['opacity'] = '0'
          gal.style['width'] = null
          gal.style['margin-left'] = null

          // do ze compelx calcs
          const h1Rect = h1.getBoundingClientRect()
          const galRect = gal.getBoundingClientRect()
          let offset = galRect.y - h1Rect.y + this.scrollOffset()
          const hDiff = galRect.height + offset - vp.h
          if (hDiff > 0) {
            // we need more height, so we reduce width according to galery ratio
            const galRatio = gal.clientWidth / gal.clientHeight;
            let newWidth = galRect.width - (hDiff * galRatio)
            newWidth = Math.max(newWidth, sectionWidth*.8)
            // we use percents because if pixels it will gros parent which induces bordel..
            gal.style['width'] = 100*newWidth/sectionWidth + '%'
            const newMargin = (-newWidth + sectionWidth) / 2
            // console.log("new width:", newWidth, "section width:", sectionWidth, "margin:", newMargin)
            if (newWidth > sectionWidth) {
              gal.style['margin-left'] = newMargin + 'px'
            } else {
              gal.style['margin'] = 'auto'
            }
          }
          // now reload slide after css transition
          gal.$vue.reload()
          gal.style['opacity'] = null
        })
      },
    },
    computed: {
      section() {return this.$route.params.section},
    },
    data: function() {
      return {
        video3D: false,
        ratio: null,
        wide: false,
        tall: false,
      }
    },
    watch: {
      section(v) {
        this.scrollTo(v)
      },
      ready(v) {
        if (v) {
          const that = this
          setTimeout(function() {
            that.resizeViewport()
            that.scrollTo(that.section)
          }, 700)
        }
      },
      ratio(v) {
        if (v < 0.6) {
          this.wide = true
          this.tall = false
        } else if (v > 1.8) {
          this.tall = true
          this.wide = false
        } else {
          this.tall = false
          this.wide = false
        }
      },
      tall(v) {
        v ?
          this.$el.classList.add("tall") :
          this.$el.classList.remove("tall")
      },
      wide(v) {
        v ?
          this.$el.classList.add("wide") :
          this.$el.classList.remove("wide")
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '../assets/css/fonts';
  @import '../assets/css/colors';
  @import '../assets/css/shapes';
  @import '../assets/css/positions';
  @import '../assets/css/mixins';

  h1 {
    font-weight: normal;
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.2em;
  }
  h1, p {
    padding: 0 4%;
  }
  section {
    margin-bottom: 6em;
    &:last-of-type {
      margin-bottom: 1em;
    }
    @include respond-below(s) {
      margin-bottom: 2.5em;
    }
  }

  .tbCarousel {
    transition: opacity .08s;
  }

  section.goals {
    margin-bottom: 2em;
    h4 {
      font-size: 1.2em;
      font-weight: normal;
      margin-bottom: .15em;
    }

    .pullright {
      text-align: right;
      @include respond-below(s) {
        text-align: center;
      }
    }
    table {
      text-align: left;
      display: inline-block;
      width: 80%;
      border-spacing: .7em 1.5em;
      @include respond-below(s) {
        width: 100%;
        border-spacing: .25em .5em;
      }

      td {
        &:first-of-type {
          text-align: right;
        }
        img {
          width: 80px;
        }
        line-height: 1em;
        @include respond-below(s) {
          img {
            width: 40px;
          }
          line-height: .8em;
        }
        p {
          @include font-regular();
          font-style: italic;
        }
        *:first-child {
          margin-top: 10px;
        }
        *:last-child {
          margin-bottom: 10px;
        }
      }
      @for $i from 1 through 4 {
        .tb#{$i} h4 {
          color: nth($colors-fancy, $i);
        }
      }
      h4, p {
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  section.financement {
    a {
      color: #6b9dc8;
      &:hover {
        text-decoration: underline;
      }
    }
    margin-bottom: 3em;
  }

  section.contact {
    position: relative;
    margin-bottom: 2em;
    & > div {
      position: relative;
      width: 90%;
      display: flex;
      flex-direction: row;
      margin: auto;
      @include respond-below(l) {
        flex-direction: column;
      }

      .social, .maps {
        flex: 1;
        align-self: center;
        text-align: center;
        width: 100%;
        iframe, .uap img {
          width: 100%;
        }
        iframe {
          max-height: 450px;
          @include respond-below(l) {
            margin-top: 30px;
            max-height: 350px;
          }
        }
      }

      .social {
        display: flex;
        flex-direction: column;
        @include respond-below(l) {
          flex-direction: row;
        }

        .uap {
          text-align: center;
          flex: 1;
          @include respond-below(l) {
            flex: 3;
          }
          img {
            max-width: 320px;
          }
        }
        .logos {
          flex: 3;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          max-width: 400px;
          margin: 1em auto auto auto;
          p {
            margin-bottom: .4em;
          }
          @include respond-below(l) {
            flex: 2;
            padding-bottom: 20px;
          }
          .ppimg {width: 150px;}
          @include respond-below(s) {
            .ppimg {width: 100px;}
          }
          .logos1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            width: 250px;
            @include respond-below(l) {
              margin: 8px auto;
            }
            @include respond-below(s) {
              width: 110px;
              margin: auto auto 10px;
            }
            margin: 10px auto 15px;
            a {
              flex: 1;
              img {
                max-width: 60px;
                max-height: 60px;
                @include respond-below(l) {
                  max-width: 45px;
                  max-height: 45px;
                }
                @include respond-below(s) {
                  max-width: 30px;
                  max-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }

  section.galery {
    position: relative;
    .thumbnails {
      width: 111%;
      margin-left: -5.5%;
      @include respond-below(l) {
        width: 111%;
        margin-left: -5.5%;
      }
      @include respond-below(s) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
</style>
