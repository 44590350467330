function _fetch(url, options=null, params=null) {
  if (!options) {
    options = {method: 'GET', headers: {}}
  }
  if (!options.headers) {
    options.headers = {}
  }
  options.headers['max-age'] = 3600*8
  // localhost cors stuff
  if (process.env.VUE_APP_NODE_ENV === "development") {
    options.headers["Access-Control-Allow-Origin"] = "*"
  } else {
    options.headers["Access-Control-Allow-Origin"] = "*.unautrepais.com"
  }
  if (typeof(params) === typeof({})) {
    url = url + "?" + new URLSearchParams(params)
  }
  return fetch(url, options)
}

export {_fetch}
