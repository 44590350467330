<template>
  <div id="app" :class="!ready? 'loading': ''">
    <div v-if="!ready" class="overlay" ref="overlay"/>
    <header class="header-menu" ref="header">
      <nav>
        <figure class="logo">
          <router-link to="/">
            <img ref="logo" src="./assets/img/logos/logo_flat.png"/>
          </router-link>
        </figure>
        <ul class="nav nav-tabs" :class="menuActive? 'active': ''">
          <li class="nav-item">
            <a href="/hameau" @click=dedupeRoute>L'éco-hameau</a>
          </li>
          <li class="nav-item">
            <a href="/equipe" @click=dedupeRoute>L'équipe</a>
          </li>
          <li class="nav-item">
            <a href="/valeurs" @click=dedupeRoute>Nos valeurs</a>
          </li>
          <li class="nav-item">
            <a href="/contact" @click=dedupeRoute>Suivez-nous</a>
          </li>
        </ul>
        <div ref="hamburg" class="hamburg" :class="menuActive? 'active': ''" @click="toggleMenu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" :viewBox="menuActive? '0 0 20 20': '0 0 15 15'">
            <path v-if='!menuActive' fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                  fill="currentColor"/>
            <path v-if="menuActive"
                  d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697s1.228-0.469,1.697,0L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0s0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15C14.817,13.62,14.817,14.38,14.348,14.849z"
                  fill="currentColor" />
          </svg>
        </div>
      </nav>
    </header>
    <div class="main" @click="menuActive = false">
      <router-view :ready=ready />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        ready: true,
        menuActive: false,
      }
    },
    created() {
      const _vue = this
      try {
        if (document.fonts) {
          // enable font loaded feature
          _vue.$data.ready = false
          const p = new Promise((resolve, reject) => {
            setTimeout(reject, 2000)
            document.fonts.onloadingdone = () => {
              resolve()
            }
          })
          p.finally(() => {_vue.$data.ready = true})
        }
      } catch {
        _vue.$data.ready = true
      }
    },
    methods: {
      dedupeRoute(ev) {
        if (!ev || !ev.target) return
        ev.preventDefault()
        const p = ev.target.pathname
        const router = this.$router
        const doIt = () => {
          router.replace({path: p})
        }
        if (p === this.$route.path) {
          // force trigger $route watch
          router.replace({path: '_'}).then(doIt)
        } else {
          doIt()
        }
      },
      toggleMenu() {this.menuActive = !this.menuActive},
      hideMenu() {this.menuActive = false},
      logoOffset() {
        const rect = this.$refs.logo.getBoundingClientRect()
        return rect.y + rect.height
      },
      headerFixed() {
        return this.$refs.hamburg ?
          window.getComputedStyle(this.$refs.hamburg).display === 'none' :
          false
      },
      scrollOffset() {
        return this.headerFixed()? this.logoOffset(): 0
      }
    },
  }

  import Vue from 'vue'
  import VueYoutube from 'vue-youtube'
  import fullscreen from 'vue-fullscreen'
  import {BootstrapVue, SpinnerPlugin} from 'bootstrap-vue'
  import VueObserveVisibility from 'vue-observe-visibility'
  import VueScrollTo from 'vue-scrollto'

  Vue.use(VueYoutube)
  Vue.use(fullscreen)
  Vue.use(BootstrapVue)
  Vue.use(SpinnerPlugin)
  Vue.use(VueObserveVisibility)
  Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
  })
</script>

<style lang="scss" scoped>
  @import 'assets/css/base';

  $border: 1px solid rgba(0, 0, 0, .2);

  .overlay {
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: $color-background;
    position: fixed;
  }

  #app {
    width: 100%;
    height: 100%;

    &.loading {
      overflow: hidden;
    }
  }

  @mixin positionning($hMenu, $wLogo, $hLogoMargin, $wLogoMarginLeft, $wLogoMarginRight, $hItemsMargin, $maxwNav, $mainTop, $hamburg:false) {
    .main {
      @if $hamburg {
        margin-top: $mainTop;
      } @else {
        margin-top: $hMenu + $mainTop;
      }
    }
    .header-menu {
      height: $hMenu;
      nav {
        max-width: $maxwNav;
        .logo {
          width: $wLogo;
          margin: $hLogoMargin $wLogoMarginRight 0 $wLogoMarginLeft;
          img {
            width: $wLogo;
          }
        }
        ul {
          li {
            &.nav-item {
              margin-top: $hItemsMargin;
            }
          }
          @if $hamburg {
            overflow: visible;
            &.active {
              display: flex;
              flex-direction: column;
              max-width: 300px;
              margin: $hMenu 0 0 auto;
              li {
                line-height: 2em;
                padding: 10px 0;
                margin-top: 0;
                width: 100%;
                height: 100%;
                border-left: $border;
                border-right: $border;
                transition: all .1s;
                &:hover {
                  background: $color-green;
                  a {
                    color: white;
                  }
                }
                a {
                  padding: 0;
                  transition: all .1s;
                }
                &:last-of-type {
                  border-bottom: $border;
                  border-radius: 0 0 40px 40px;
                }
              }
            }
            display: none;
          } @else {
            display: flex;
            overflow: hidden;
            li {
              line-height: .87em;
              a {
                padding: 20px 0;
              }
            }
          }
        }
        .hamburg {
          @if $hamburg == false {
            display: none;
          } @else {
            display: block;
          }
        }
      }
    }
  }

  @include positionning(65px, 280px, 25px, 25px, 18px, 13px, 1330px, 37px);
  @include respond-below(l) {
    @include positionning(60px, 260px, 23px, 65px, 0, 15px, 950px, 37px, $hamburg: true);
  }
  @include respond-below(s) {
    @include positionning(60px, 220px, 29px, 12px, 0, 17px, 850px, 25px, $hamburg: true);
  }

  .header-menu {
    position: fixed;
    @include respond-below(l) {
      position: relative;
    }
    z-index: 99;
    top: 0;
    width: 100%;
    text-align: center;
    background: white;
    border-bottom: $border;
    nav {
      position: relative;
      display: flex;
      margin: auto;
      height: 100%;
      .logo {
        display: inline;
        position: relative;
        img {
          display: inline;
          vertical-align: middle;
        }
      }
      ul {
        margin: auto;
        height: 100%;
        width: 100%;
        padding-left: 0;
        position: relative;
        background: white;
        li {
          font-size: 1.1em;
          list-style: none;
          flex: 1;
          text-align: center;
          background: white;
          &.nav-item {
            align-self: center;
          }
          a {
            display: block;
            width: 100%; height: 100%;
            border-bottom: 1px solid transparent;
          }
        }
      }
      .hamburg {
        box-sizing: content-box;
        svg {
          position: absolute;
          width: 30px;
          height: 30px;
          padding: 18px 50px 17px 88px;
          right: 0;
          top: 0;
          path {
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>
