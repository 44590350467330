import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home'

export const router = new VueRouter({
  mode: 'history',
  routes: [{
      path: '/:section?',
      component: Home,
    },
    {path: '*', redirect: '/'}
  ]
})

Vue.use(VueRouter)
