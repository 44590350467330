<template>
  <div class="Youtube" @click="close">
    <a class="biclose" @click="close" aria-keyshortcuts="Escape"></a>
    <div class="container" :class="ready?'mounted':''">
      <b-spinner v-if="!ready" class="spinner"/>
      <youtube ref="youtube" class="ytplayer"
               :video-id="id" :player-vars="playerVars"
               @ready="onReady"
               v-on:keypress.esc="close" />
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      title: String,
      id: String,
      controls: {
        default: 1,
        type: Number,
      },
      fullscreen: {
        default: true,
        type: Boolean,
      },
      closeButton: {
        default: false,
        type: Boolean,
      },
      autoplay: {
        default: 1,
        type: Number,
      },
    },
    computed: {
      playerVars() {
        return {
          autoplay: this.autoplay,
          origin: window.location.origin,
        }
      },
    },
    mounted() {
      this.origin = this.$el.parentNode;
      if (this.fullscreenState) {
        this.$fullscreen.toggle(this.$el, {
          pageOnly: true,
          callback: this.fsChange,
        }, true);
      }
    },
    data() {
      return {
        fullscreenState: this.fullscreen,
        origin: undefined,
        topOffset: 0,
        ready: false,
        mounted: false,
      }
    },
    methods: {
      onReady() {
        this.ready = true;
      },
      close() {
        this.$fullscreen.exit();
        // let parent handle close event
      },
      fsChange(on) {
        this.fullscreenState = on;
        this.$emit('fullscreen', on);
        if (!on) {
          this.moveToOrigin();
          this.closed();
        } else {
          this.moveToRoot();
        }
      },
      closed() {
        this.$emit('close');
      },
      moveToRoot() {
        this.topOffset = -(document.documentElement.scrollTop);
        document.body.style.top = this.topOffset + 'px';
        document.body.classList.add("noscroll");
        this.$el.parentNode.removeChild(this.$el);
        document.body.appendChild(this.$el);
      },
      moveToOrigin() {
        document.body.removeChild(this.$el);
        this.origin.appendChild(this.$el);
        document.body.classList.remove("noscroll");
        window.scrollTo(0, -this.topOffset);
      },
    },
  };
</script>

<style lang="scss">
  @import '../assets/css/positions';
  @import '../assets/css/shapes';
  .Youtube {
    z-index: 99999;
    background: rgba(50, 50, 50, .8);
    &:hover {
      cursor: pointer;
    }
    .container {
      @include absoluteCenter();
      width: 100%;
      height: 85%;
      text-align: center;
      .spinner {
        color: white;
        margin: auto;
        position: absolute;
        top: 48%; left: 48%;
        z-index: 0;
      }
      iframe {
        width: 100%;
        height: 100%;
        z-index: 1;
        border: 0;
      }
    }
  }
</style>
